/*
 * File: Contact.module.css
 * Project: autofi-front
 * File Created: Wednesday, 1st March 2023 04:27:02
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 8th March 2023 04:26:08
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.contactHolder{
    display: flex;
    justify-content: center;
    min-height: 77.7vh;
}

.imagePlaceholder{
    width: 45%;
}
.imagePlaceholder > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
}
.buttonStep{
    height: 3rem;
    min-width: 20%;
    max-width: 30%;
    color: var(--theme-color);
    border: solid 1px var(--theme-color);
    background-color: transparent;
    border-radius: 5px;
}
.buttonStep:hover{
    color: var(--white);
    background-color: var(--theme-color);
    transition: 0.2s ease-in-out;
}
.buttonNext{
    height: 3rem;
    min-width: 20%;
    max-width: 30%;
    color: var(--white);
    border: solid 1px var(--theme-color);
    background-color: var(--theme-color);
    border-radius: 5px;
}
.formStyle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.buttonStepDisabled{
    height: 3rem;
    min-width: 20%;
    max-width: 30%;
    color: var(--theme-bg-color);
    border: solid 1px var(--theme-bg-color);
    background-color: transparent;
    border-radius: 5px;
}

.buttonPos{
    display: flex;
    justify-content: flex-end;
    width: 80%;
    padding: 1rem 0;
}

.buttonPosSplit{
justify-content: space-between;
}

.lineBlock {
    display: flex;
    width: 100%;
    align-content: center;
    flex-direction: row;
    gap: 2rem;
    flex: 0 0 33%;
}

.inputBlock {
    float: left;
    width: 33%;
}

.input {
    display: block;
    border-radius: 3px;
}
.inputHolder{
    width: 80%;
    margin: auto;
}

.title {
    width: 100%;
}

.inputSize {
    width: 100%;
    height: 2.2rem;
    padding: 0.1rem 0.5rem;
}

.inputSelect {
    color: var(--bs-body-color);
    background-color: #e9e9ed;
}

.mandatory {
    width: 80%;
    color: red;
    font-style: oblique;
    font-size: 0.9rem;
    margin: auto;
}
.commentaryField{
    border: 0;
    border-bottom: solid 1px var(--theme-color);
    resize: none;
    width: 100%;
}

.commentaryField:focus-visible{
    outline: solid 1px var(--theme-color);
}
.modal{
    position: absolute;
    z-index: 15;
    right: 1rem;
    bottom: 1rem;
    height: 10%;
    min-height: 8rem;
    width: 20%;
    min-width: 15rem;
    border: 2px solid var(--theme-color);
    border-radius: 5px;
    background-color: var(--white);
}

@media ( width < 1300px ){
    .contactForm{
        width: 85%;
    }
    .imagePlaceholder{
        width: 25%;
    }
}

@media ( width < 600px ){
    .contactForm{
        width: 100%;
    }
    .imagePlaceholder{
        display: none;
    }
    .buttonPos{
        width: 100%;
    }
    .formStyle{
        max-width: 100% !important;
        width: 100%;
        height: 100%;
    }
    .inputHolder{
        width: 100%;
        margin: 0;
    }
}
