/*
 * File: Theme.css
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    font-family: var(--theme-text-font-family);
    color: var(--theme-color);
}

.primary-color {
    color: var(--root-colors-first);
}

.secondary-color {
    color: var(--root-colors-second);
}

.tercary-color {
    color: var(--root-colors-third);
}

.fourth-color {
    color: var(--root-colors-fourth);
}

.fifth-color {
    color: var(--root-colors-fifth);
}

.sixth-color {
    color: var(--root-colors-sixth);
}

.bg-primary-color {
    background-color: var(--root-colors-first);
}

.bg-secondary-color {
    background-color: var(--root-colors-second);
}

.bg-tercary-color {
    background-color: var(--root-colors-third);
}

.bg-fourth-color {
    background-color: var(--root-colors-fourth);
}

.bg-fifth-color {
    background-color: var(--root-colors-fifth);
}

.bg-sixth-color {
    background-color: var(--root-colors-sixth);
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.fullscreen {
    width: 100vw;
    height: 100vh;
}

.full-parent-space {
    width: 100%;
    height: 100%;
}

.h-center-div {
    margin: 0 auto;
}

.v-center-div {
    margin: auto;
}

.v-center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-center-content {
    text-align: center;
}

.align-content-right {
    text-align: right;
}

.inline-center-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.absolute-bottom {
    position: absolute;
    bottom: 0;
}

.margined {
    margin: 10px;
}

.no-margin {
    margin: 0;
}

.no-margin-bottom {
    margin: 0;
}

.margin-top-bottom {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-top {
    margin-top: 15px;
}

.margin-top-auto {
    margin-top: auto;
}


.big-margin-top {
    margin-top: 25px;
}

.margin-bottom {
    margin-bottom: 15px;
}

.margin-bottom-large {
    margin-bottom: 25px;
}

.margin-bottom-huge {
    margin-bottom: 40px;
}


.margin-left-right {
    margin-left: 15px;
    margin-right: 15px;
}

.big-margin-left-right {
    margin-left: 25px;
    margin-right: 25px;
}

.margin-left {
    margin-left: 15px;
}

.big-margin-left {
    margin-left: 25px;
}

.padded {
    padding: 10px;
}

.block {
    display: block;
}

.inline {
    display: inline-block;
}

.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
}

.small-round-border {
    border-radius: 4px;
}

.medium-round-border {
    border-radius: 8px;
}

.large-round-border {
    border-radius: 12px;
}

.classic-shadow {
    box-shadow: 0px 0px 10px black;
}

.shadow-right-bottom {
    box-shadow: 10px 10px 10px black;
}

.shadow-bottom {
    box-shadow: 0px 15px 10px -15px #111;
}

.small-font-1 {
    font-size: 0.75rem;
}

.small-font-2 {
    font-size: 0.92rem;
}

.medium-font-1 {
    font-size: 1rem;
}

.medium-font-2 {
    font-size: 1.12rem;
}

.large-font-1 {
    font-size: 1.5rem;
}

.large-font-2 {
    font-size: 1.75rem;
}

.extra-large-font_1 {
    font-size: 2rem;
}

.extra-large-font_2 {
    font-size: 2.5rem;
}

.small-lineheight-1 {
    line-height: 0.75rem;
}

.small-lineheight-2 {
    line-height: 1rem;
}

.medium-lineheight-1 {
    line-height: 1.12rem;
}

.medium-lineheight-2 {
    line-height: 1.5rem;
}

.large-lineheight-1 {
    line-height: 1.75rem;
}

.large-lineheight-2 {
    line-height: 2rem;
}

.extra-lineheight-1 {
    line-height: 2.5rem;
}

.extra-lineheight-2 {
    line-height: 2.75rem;
}

.remaining-space-column {
  display: flex;
  flex-flow: column;
}

.remaining-space-content {
  flex-grow: 100;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-horizontal-auto {
    overflow-x: auto;
    overflow-y: hidden;
}

.overflow-vertical-auto {
    overflow-x: hidden;
    overflow-y: auto;
}

.content-forced-on-same-line {
    white-space: nowrap;
    display: inline-block;
}

.flex-basis-full {
    flex-basis: 100%;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column {
    flex-direction: column;
}

.font-weight-100 {
    font-weight: 100;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-900 {
    font-weight: 900;
}

.italic {
    font-style: italic;
}

.flex-valign-center {
    align-items: center;
}

.flex-halign-center {
    justify-content: center;
}

.flex-grow {
    flex-grow: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.fit-content {
    width: fit-content;
    height: fit-content;
}

.underline {
    text-decoration: underline;
}

.flex-halign-left {
    justify-content: left;
}

.flex-halign-right {
    justify-content: right;
}

.sticky-top {
    position: sticky;
    top: 25px;
}

.relative {
    position: relative;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-align-end {
    align-items: flex-end;
}
