/*
 * File: RegNbInput.module.css
 * Project: autofi-front
 * File Created: Thursday, 16th March 2023 04:54:02
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd March 2023 02:07:43
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.mandatory {
    color: red;
}

.wrongInput:focus,
.wrongInput {
    border: 0.2rem solid red;
}

.regNb {
    display: inline-flex;
    height: 5rem;
    margin: 1rem auto;
}

.noSpace {
    height: 100%;
    padding: 0;
    margin: 0;
}

.nbPlate {
    font-size: 3.5rem;
    width: 7em;
    color: black !important;
    text-align: center;
    background-color: whitesmoke !important;
    border: 0.2rem solid #386DC0 !important;
}

.contentLayouyt {
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-items: start;
}

@media( width < 500px ){
    .nbPlate{
        width: 15rem;
        font-size: 2rem;
    }
}
