/*
 * File: Reprise.tsx
 * Project: autofi-front
 * File Created: Thursday, 16th March 2023 03:33:13
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 20th March 2023 12:38:14
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.buyBackHolder{
    display: flex;
    min-height: 77.7vh;
}
.buyBackImage{
    width: 45%;
}
.buyBackImage > img {
    height: 100%;
    width: 100%;
    filter: brightness(1.5);
    object-fit: cover;
}
.buyBackForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 55%;
}
.formStyle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.formPhotoHolder{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}
.formPhoto{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}
.buttonStep,
.buttonNext,
.buttonStepDisabled{
    height: 3rem;
    min-width: 20%;
    max-width: 30%;
    color: var(--theme-color);
    border: solid 1px var(--theme-color);
    background-color: transparent;
    border-radius: 5px;
}
.buttonStepDisabled{
    color: var(--theme-bg-color);
    border: solid 1px var(--theme-bg-color);
}
.buttonNext{
    color: var(--white);
    background-color: var(--theme-color);
}
.buttonNext:focus{
    border: 0;
}
.buttonStep:hover{
    color: var(--white);
    background-color: var(--theme-color);
    transition: 0.2s ease-in-out;
}
.stepOne{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 80%;
    letter-spacing: 1px;
}

.progressBarStyle{
    width: 100%;
}
.buttonPos{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 80%;
    padding: 1rem 0;
}
.wrongRegNb{
    color: red;
    /* font-weight: bold; */
}
.title {
    text-decoration: underline var(--theme-color);
    text-align: center;
}
.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.scrollable {
    overflow-y: no-scroll;
    overflow-x: scroll;
}

.sectionTitle {
    margin-top: 1rem;
    text-align: center;
    text-transform: uppercase;
}

.input {
    white-space: nowrap;
    height: 2rem;
    margin-top: 0.2rem;
    padding-top: 0rem;
    padding-right: 0 !important;
    /* iOS Safari */
    -webkit-touch-callout: auto;
    /* Safari */
    -webkit-user-select: auto;
    user-select: auto;
}

.input:disabled {
    color: gray;
}

.input:disabled:hover {
    background-color: whitesmoke !important;
}

.inputGroup {
    flex: 1;
    display: inline-block;
    align-self: flex-end;
    margin-top: 1rem;
    width: 100%;
    margin-right: 1rem;
}

.inputGroup:last-child {
    margin-right: auto;
}

.groupLine {
    display: flex;
    flex: 2 2;
    float: left;
}

.input_submit {
    min-height: 60px;
    margin-top: 20px;
}

.input_submit:disabled {
    background-color: rgb(221, 158, 156);
}

.centered {
    margin: auto;
}

.mySelect {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.inputImg {
    position: relative;
    border: none;
    object-fit: cover;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    width: 100%;
    height: 7rem;
}
.closeButton{
    border: 0;
    position: relative;
    top: -75%;
    left: 2%;
    width: 2rem;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--theme-color);
}

.picture-input {
    display: flex-shrink;
    color: red;
    font-family: AbeeZee, sans-serif;
    font-size: 12px;
}
.photoWished{
    font-weight: bold;
    color: var(--theme-bg-color);
    text-decoration: underline var(--theme-color);
}

.file-container {
    display: flex;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    margin: 0 auto;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-box-shadow: 1px 1px 10px #000;
    -webkit-box-shadow: 1px 1px 5px #000;
    box-shadow: 1px 1px 10px #000;
    padding: 0.5rem;
}
.formHolder{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.commentaryField{
    border: 0;
    border-bottom: solid 1px var(--theme-color);
    resize: none;
    width: 100%;
}
.commentaryField:focus-visible{
    outline: solid 1px var(--theme-color);
}
.contactForm{
    width: 80%;
    margin: auto;
}
.mandatory {
    width: 80%;
    color: red;
    font-style: oblique;
    font-size: 0.9rem;
    margin: auto;
}

.spinner{
    position: relative;
    width: 100%;
    height: 100%;
}
.inputContainer {
    display: flex;
    flex-shrink: 1;
    flex: 1;
    float: left;
    margin: 0 auto auto auto;
}

.inputPicture {
    vertical-align: top;
    margin: 0 auto auto auto;
}

#div_formContainer {
    width: 100%;
}

input[type=text]{
     /* iOS Safari */
     -webkit-touch-callout: auto;
     /* Safari */
     -webkit-user-select: auto;
     user-select: auto;

}

.myCol {
    align-self: flex-end;
    margin-bottom: 0 !important;
}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 1199px) {
    .myRow {
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        margin-right: -15px;
        margin-left: -15px;
    }

    .myCol {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .groupLine {
        flex-direction: column;
        max-width: 80%;
        width: 80%;
        flex-shrink: 1;
        flex: 1;
        float: left;
    }

    .input_submit {
        width: 80%;
        font-size: 1.5rem;
    }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    .myRow {
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        display: flex;
        margin-right: -15px;
        margin-left: -15px;
    }

    .myCol {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .groupLine {
        display: flex;
        flex-shrink: 1;
        flex: 1;
        float: left;
    }

    #div_form {
        width: 60%;
    }

}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .input:disabled {
        color: darkslategray;
    }

    .input {
        padding-top: -0.1rem;
        margin-top: -0.1rem;
    }
}


@media ( width < 1300px ) {
    .buyBackForm{
        width: 85%;
    }
    .buyBackImage{
        width: 25%;
    }
}

@media ( width < 600px ){
    .buyBackForm{
        width: 100%;
    }
    .buyBackImage{
        display: none;
    }
    .stepOne{
        width: 100%;
    }
    .buttonPos{
        width: 100%;
    }
    .formStyle{
        max-width: 100% !important;
        width: 100%;
    }
    .contactForm{
        width: 100%;
        margin: 0;
    }
}
