/*
 * File: AdressContact.module.css
 * Project: autofi-front
 * File Created: Thursday, 2nd March 2023 09:47:08
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 9th March 2023 09:05:45
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.contactForm {
    display: grid;
    flex-direction: column;
    align-items: center;
}

.lineBlock {
    display: flex;
    width: 80%;
    justify-content: center;
    margin: auto;
    gap: 2rem;
}

.inputBlock {
    float: left;
    width: 33%;
}

.addressBlock {
    display: flex;
    align-items: flex-start;
    width: 80%;
    flex-direction: column;
    margin: auto;
}

.input {
    display: block;
    border-radius: 3px;
}

.title {
    width: 100%;
}

.inputSize {
    background-color: var(--white) !important;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--theme-color) !important;
    color: var(--theme-bg-color) !important;
    width: 100%;
    height: 2.2rem;
    padding: 0.1rem 0.5rem;
}

.inputSize:focus-visible{
    outline: solid 1px var(--theme-color);
}

.addressSize {
    width: 100%;
}

.inputSelect {
    color: var(--bs-body-color);
    background-color: #e9e9ed;
}
.inputSelect:hover{
    border-color: var(--theme-color) !important;
}
.mandatory {
    color: red;
}

.wrongInput:focus,
.wrongInput {
    border: 0.2rem solid red;
}

@media ( width < 800px ){
    .inputBlock{
        width: 80%;
    }
    .lineBlock{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 1rem auto;
    }
    .addressBlock{
        width: 80%;
    }
}

@media ( width < 600px ){
    .inputBlock{
        width: 100%;
    }
    .lineBlock{
        width: 100%;
    }
    .addressBlock{
        width: 100%;
    }
}
