/*
 * File: LoadingScreen.module.css
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

#divBackground {
    width: 100%;
    height: 100%;
}

#spinner {
    color: var(--root-colors-third);
    border-width: 20px;
    width: 20vh;
    height : 20vh;
}
