/*
 * File: CarValuesInput.tsx
 * Project: autofi-front
 * File Created: Friday, 17th March 2023 06:03:52
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 20th March 2023 11:52:08
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

 .mandatory {
    color: red;
}

.contentLayout {
    display: flex;
    flex-direction: column;
    align-content: left;
    justify-items: start;
    margin-bottom: 1rem;
}

.title {
    text-decoration: underline var(--theme-color);
}
