/*
 * File: Contact.module.css
 * Project: autofi-front
 * File Created: Wednesday, 1st March 2023 04:27:02
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 8th March 2023 04:21:48
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.contactForm {
    display: grid;
    flex-direction: column;
    align-items: center;
}

.lineBlock {
    display: flex;
    width: 80%;
    align-content: center;
    flex-direction: row;
    gap: 2rem;
    margin: auto;
}

.inputBlock {
    float: left;
    width: 50%;
    margin: auto;
}

.titleHolder{
    width: fit-content;
    margin: auto;
}
.title {
    width: 100%;
    text-align: center;
    text-decoration: underline var(--theme-color);
}
.input {
    display: block;
    font-size: 0.9rem;
    letter-spacing: 1px;
}

.inputSize {
    background-color: var(--white) !important;
    border-bottom: 1px solid var(--theme-color) !important;
    color: var(--theme-bg-color) !important;
    width: 100%;
    height: 2.2rem;
    padding: 0.1rem 0.5rem;
}
.inputNumber{
    appearance: textfield;

}
.inputSelect {
    display: block;
    background-color: #e9e9ed;
}
@media ( width < 500px ){
    .imagePlaceholder{
        display: none;
    }
    .lineBlock{
        flex-direction: column;
        width: 100%;
    }
    .inputBlock{
        width: 100%;
    }
}
